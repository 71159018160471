import React              from 'react'
import Helmet             from 'react-helmet'
import { Link }           from 'gatsby'

import config             from '../../../data/site-config'

import Layout             from '../../components/layout'
import MainWrapper        from '../../components/MainWrapper/MainWrapper'
import ServicePageHero    from '../../components/Services/ServicePageHero'
import ServicesPagination from '../../components/Services/ServicesPagination'

const DesignServicesPage = () => (
  <Layout className="service-page content-page" style={{backgroundColor: '#cd96e5'}}>

    <Helmet
      title={`🎨 Design Services • UX, UI, Web Design • Spacetime Design Agency`}
      meta={[
        { name: 'description', content: 'Spacetime provides web design services that exceed the ordinary and lead to success' }
      ]}
      link={[
        { rel: 'canonical', href: `${config.siteUrl}/services/design/` }
      ]}
      bodyAttributes={{
        class: 'dark-theme'
      }}
    />

    <div>

      <ServicePageHero
        name={`design`}
        title={`Design Services`}
        description={`design is the communication of the business, brand, identity, and&nbsp;product`}
        icon={`🎨`}
        iconPath={``}
        bgColor={`#cd96e5`}
        textColor={`black`} />

      <MainWrapper>

        <section className="pv6">
          <div className="row narrow">
            <p>Design embodies the core spirit of your brand's personality. It's often the first thing someone will notice about your brand, and yes, people do judge a book by its cover.</p>
            <p>We specialize in thoughtful and evocative design backed by practical business goals. We don't "make pretty pictures" or "push pixels", so if that's what you are looking for then we are probably not the right fit.</p>
            <p>What we do offer, and guarantee, is unique and thoughtful experience design that will transform your brand from <em>meh</em> to <em>WOW!</em> in no time flat.</p>
            <h5 className="">What do you need?</h5>
            <ul className="system-sans o-70 lh-copy dark-purple f6 fw5 mv4">
              <li className="mv1">Branding &amp; logo</li>
              <li className="mv1">Wireframing</li>
              <li className="mv1">Interactive Prototypes</li>
              <li className="mv1">User Experience Design (UX)</li>
              <li className="mv1">User Interface Design (UI)</li>
              <li className="mv1">Web design</li>
              <li className="mv1">App design</li>
              <li className="mv1">Native mobile design</li>
              <li className="mv1">Responsive web design</li>
              <li className="mv1">Design System</li>
              <li className="mv1">Pattern Library</li>
              <li className="mv1">Design Concepts</li>
              <li className="mv1">Train your internal design team</li>
            </ul>
          </div>
        </section>

        <section className="row pv4 pv5-ns mv5 bg-washed-yellow">
          <div className="tc">
            <h3 className="f2">Stand out from the crowd through exceptional <span style={{color: '#cd96e5'}}>Design</span>.</h3>
            <Link
              to="/contact/"
              className="ttu f7 fw8 tc link br1 ph5 pv2 pv3-ns dib mv4 dim white br-pill"
              style={{backgroundColor: '#cd96e5'}}>
                Contact us →
            </Link>
          </div>
        </section>

        <ServicesPagination
          link={`websites`}
          name={`Website`}
          tagline={`stand out from the crowd with stellar website design`}
          color={`#1de9b6`}
        />

      </MainWrapper>
    </div>
  </Layout>
)

export default DesignServicesPage
